import React from 'react'

import { ButtonText } from 'components/Texts'

import { ButtonContent, BaseButtonStyles } from './styledComponents'

const BaseButton = ({ children, noPadding, ...props }) => (
  <BaseButtonStyles {...props}>
    <ButtonContent noPadding={noPadding}>
      <ButtonText>{children}</ButtonText>
    </ButtonContent>
  </BaseButtonStyles>
)

export default BaseButton
