import React from 'react'

const Twitter = ({ width = '48px', height = '48px', ...rest }) => (
  <svg viewBox="0 0 48 48" width={width} height={height} {...rest}>
    <path
      fill="#188DB7"
      fillRule="evenodd"
      d="M27.334 14.626c-2.096.763-3.42 2.73-3.27 4.881l.051.83-.838-.101c-3.05-.39-5.716-1.711-7.98-3.932l-1.106-1.102-.285.814c-.603 1.814-.217 3.729 1.04 5.017.67.712.519.813-.637.39-.403-.136-.755-.237-.788-.187-.117.12.285 1.66.603 2.271.436.848 1.324 1.678 2.297 2.17l.821.39-.972.016c-.938 0-.972.017-.87.374.334 1.101 1.658 2.271 3.134 2.78l1.039.355-.905.542a9.41 9.41 0 01-4.493 1.255c-.755.016-1.375.084-1.375.135 0 .169 2.046 1.118 3.235 1.491 3.57 1.102 7.812.627 10.997-1.254 2.263-1.339 4.526-4 5.582-6.577.57-1.373 1.14-3.88 1.14-5.083 0-.78.05-.882.99-1.814.553-.542 1.072-1.135 1.173-1.305.168-.322.15-.322-.704-.034-1.425.509-1.627.44-.922-.322.52-.542 1.14-1.525 1.14-1.813 0-.05-.251.033-.537.186-.301.17-.972.424-1.475.576l-.905.289-.821-.56c-.453-.305-1.089-.644-1.425-.746-.855-.236-2.163-.203-2.934.068zM24 48C10.746 48 0 37.255 0 24S10.746 0 24 0s24 10.745 24 24-10.746 24-24 24z"
      clipRule="evenodd"
    />
  </svg>
)

export default Twitter
