import React from 'react'

const Search = ({ width = '20px', height = '20px', ...rest }) => (
  <svg viewBox="0 0 20 20" width={width} height={height} {...rest}>
    <path
      fill="#000"
      fillOpacity={0.6}
      fillRule="evenodd"
      d="M3.5 9.167a5.667 5.667 0 119.882 3.786l-.429.43A5.667 5.667 0 013.5 9.166zm10.335 6.082a7.667 7.667 0 111.414-1.414l2.958 2.958.707.707-1.414 1.414-.707-.707-2.958-2.958z"
      clipRule="evenodd"
    />
  </svg>
)

export default Search
