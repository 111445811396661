import React from 'react'

const Close = ({ width = '48px', height = '48px', ...rest }) => (
  <svg viewBox="0 0 48 48" width={width} height={height} {...rest}>
    <path
      d="M36 12L12 36m0-24l24 24"
      stroke="#000"
      strokeOpacity={0.6}
      strokeWidth={2}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)

export default Close
