import React, { useState, useEffect } from 'react'

import Cookies from 'components/Cookies'

import Header from './Header'
import Footer from './Footer'
import { Page, Main } from './styledComponents'
import { LayoutContextProvider } from './context'
import '../../fonts/fonts.css'

const Layout = ({ children }) => {
  //  this needs to be done due to Gatsby SSR and using document object in Cookies component
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => setIsBrowser(true))

  return (
    <LayoutContextProvider>
      <Page>
        <Header />
        <Main>{children}</Main>
        {isBrowser && <Cookies />}
        <Footer />
      </Page>
    </LayoutContextProvider>
  )
}

export default Layout
