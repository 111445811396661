import React from 'react'

import Translation from 'utils/Translation'
import {
  JOB_PLATFORM_URL,
  INTERNAL_BLOG_URL,
  BOOKING_PLATFORM_URL,
} from 'consts'

export const userRoles = {
  CANDIDATE: 'candidate',
  COMPANY: 'company',
}

export const PLATFORM_LINKS = [
  { href: JOB_PLATFORM_URL, label: <Translation id="jobs" /> },
]

export const PRODUCTS_LINKS_CONTENT = [
  {
    title: <Translation id="header.products" />,
    items: [
      {
        href: BOOKING_PLATFORM_URL,
        label: <Translation id="header.securityServices" />,
      },
      {
        href: JOB_PLATFORM_URL,
        label: <Translation id="jobs" />,
      },
    ],
  },
]

export const MOBILE_PLATFORM_LINKS = [
  {
    to: JOB_PLATFORM_URL,
    label: <Translation id="jobs" />,
    isPlatformLink: true,
  },
  {
    href: BOOKING_PLATFORM_URL,
    label: <Translation id="header.securityServices" />,
  },
  {
    href: INTERNAL_BLOG_URL,
    label: <Translation id="header.blog" />,
    isPlatformLink: true,
  },
]

export const JOB_PLATFORM_ACTION_LINK_COMPANY = `${JOB_PLATFORM_URL}?loginModalMode=${userRoles.COMPANY}`
export const JOB_PLATFORM_ACTION_LINK_CANDIDATE = `${JOB_PLATFORM_URL}?loginModalMode=${userRoles.CANDIDATE}`

export const PLATFORM_ACTION_LINKS = [
  {
    buttonLabel: <Translation id="header.securityServiceProvider" />,
    options: [
      {
        href: JOB_PLATFORM_ACTION_LINK_COMPANY,
        label: <Translation id="header.findEmployees" />,
      },
    ],
  },

  {
    buttonLabel: <Translation id="header.login" />,
    options: [
      {
        href: JOB_PLATFORM_ACTION_LINK_CANDIDATE,
        label: <Translation id="header.findJob" />,
      },
    ],
  },
]
