import React from 'react'

const Facebook = ({ width = '48px', height = '48px', ...rest }) => (
  <svg viewBox="0 0 48 48" width={width} height={height} {...rest}>
    <path
      fill="#4460A9"
      fillRule="evenodd"
      d="M20.612 37.056h5.395V23.998h3.6l.476-4.499h-4.076l.006-2.253c0-1.172.112-1.802 1.795-1.802h2.25v-4.5h-3.6c-4.324 0-5.846 2.183-5.846 5.854v2.701h-2.695V24h2.695v13.057zM24 48C10.746 48 0 37.255 0 24S10.746 0 24 0s24 10.745 24 24-10.746 24-24 24z"
      clipRule="evenodd"
    />
  </svg>
)

export default Facebook
