import React from 'react'
import Highlighter from 'react-highlight-words'

export const handleEscape = setIsSearchMenuOpen => {
  document.onkeydown = evt => {
    evt = evt || window.event
    if (evt.keyCode === 27) {
      setIsSearchMenuOpen(false)
    }
  }
}

export const handleEnterPress = searchIconRef => {
  if (searchIconRef) {
    searchIconRef.onkeydown = evt => {
      evt = evt || window.event
      if (evt.keyCode === 13) {
        searchIconRef.click()
      }
    }
  }
}

export const highlightText = (id, text, query) => (
  <Highlighter
    highlightClassName={id}
    searchWords={[query]}
    autoEscape
    textToHighlight={text}
  />
)
