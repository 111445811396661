import React, { Fragment } from 'react'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import { LANDING_PAGE_URL } from 'consts'
import { theme } from 'styles/themes'
import { useMatchMedia } from 'hooks'
import Translation from 'utils/Translation'

import { SECIcon, User, SmallClose } from 'components/Icons'
import { Headline6 } from 'components/Texts'
import { NoStylesExternalLink } from 'components/Links'
import Search from 'components/Search'

import {
  HeaderWrapper,
  InternalWrapper,
  Inner,
  LogoWrapper,
  DesktopHideWrapper,
  MobileHideWrapper,
  MobileIconWrapper,
  GenericHeaderDropdownWrapper,
} from './styledComponents'
import {
  AppLinks,
  Separator,
  MobileMenu,
  AccountButtons,
  GenericTextDropdown,
} from './components'
import { useLayout } from './context'
import { PRODUCTS_LINKS_CONTENT } from './consts'

const Header = () => {
  const { isMobileMenuOpen, toggleMobileMenu } = useLayout()
  const { isMobile, isTablet } = useMatchMedia()

  const {
    allContentfulHeaderDropdown: { nodes: headerDropdowns = [] } = {},
  } = useStaticQuery(graphql`
    query HeaderDropdownsQuery {
      allContentfulHeaderDropdown(filter: { platform: { in: "news" } }) {
        nodes {
          title
          items {
            href
            to
            label
          }
        }
      }
    }
  `)

  const MobileMenuIcon = isMobileMenuOpen ? SmallClose : User

  const MobileIconsWrapper = isMobile || isTablet ? Flex : Fragment

  return (
    <>
      <HeaderWrapper bg={theme.NewColors.light}>
        <Inner>
          <InternalWrapper>
            <Flex alignItems="center">
              {isMobileMenuOpen && (isMobile || isTablet) ? (
                <Headline6 scaleDownOnMobile bold>
                  <Translation id="header.menu" />
                </Headline6>
              ) : (
                <NoStylesExternalLink
                  href={LANDING_PAGE_URL}
                  css={`
                    svg {
                      width: 4.9em;
                      font-size: 30px;
                    }
                  `}
                >
                  <LogoWrapper>
                    <SECIcon />
                  </LogoWrapper>
                </NoStylesExternalLink>
              )}
              <MobileHideWrapper isTabletIncluded>
                <Flex alignItems="center" ml="40px">
                  {PRODUCTS_LINKS_CONTENT.map(({ title, items }) => (
                    <GenericHeaderDropdownWrapper key={title}>
                      <GenericTextDropdown
                        mainBarTitle={title}
                        options={items}
                      />
                    </GenericHeaderDropdownWrapper>
                  ))}
                  <AppLinks />
                </Flex>
              </MobileHideWrapper>
            </Flex>

            {/* Desktop Menu */}
            <MobileHideWrapper isTabletIncluded>
              <Flex alignItems="center">
                <Flex>
                  {(headerDropdowns || []).map(({ title, items }) => (
                    <GenericHeaderDropdownWrapper key={title}>
                      <GenericTextDropdown
                        mainBarTitle={title}
                        options={items}
                      />
                    </GenericHeaderDropdownWrapper>
                  ))}
                </Flex>
                <Separator mx="20px" />

                <AccountButtons />
              </Flex>
            </MobileHideWrapper>

            {/* Mobile menu */}
            <MobileIconsWrapper>
              {!isMobileMenuOpen && <Search />}
              <DesktopHideWrapper
                isTabletNotIncluded
                css={`
                  color: ${theme.NewColors.mediumBlack};
                `}
              >
                <MobileIconWrapper ml="20px" isInteractive>
                  <MobileMenuIcon
                    width="20px"
                    height="20px"
                    color={theme.NewColors.mediumBlack}
                    onClick={() => toggleMobileMenu()}
                  />
                </MobileIconWrapper>
                <MobileMenu headerDropdowns={headerDropdowns} />
              </DesktopHideWrapper>
            </MobileIconsWrapper>
          </InternalWrapper>
        </Inner>
      </HeaderWrapper>
    </>
  )
}

export default Header
