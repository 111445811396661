import styled from 'styled-components'
import { Box } from '@rebass/grid'

import { theme } from 'styles/themes'

const Separator = styled(Box)`
  height: 28px;
  width: 1px;
  background-color: ${theme.NewColors.primary400};
`

export default Separator
