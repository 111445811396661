import React from 'react'
import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'
import { Link } from 'gatsby'

import { theme } from 'styles/themes'

import { DualPurposeAnchor } from 'components/Links'
import { buttonTextStyles } from 'components/Texts'

import { PLATFORM_LINKS } from '../consts'

const commonStyles = css`
  ${buttonTextStyles}
  color: ${theme.NewColors.mediumBlack};
  font-weight: 500;


  :first-child {
    margin-right: 25px;
  }
`

const StyledAnchor = styled.a`
  ${commonStyles}
`
const StyledLink = styled(Link)`
  ${commonStyles}
`

const AppLinks = () => (
  <Flex ml="40px">
    {PLATFORM_LINKS.map(({ to, label, href }) => (
      <DualPurposeAnchor
        key={to || href}
        LinkComponent={StyledLink}
        Anchor={StyledAnchor}
        {...{ to, href }}
        activeStyle={{ color: theme.NewColors.secondary }}
        partiallyActive
      >
        {label}
      </DualPurposeAnchor>
    ))}
  </Flex>
)

export default AppLinks
