import { useState, useEffect } from 'react'

import { isPreview } from 'consts'

import usePathname from './usePathname'

const usePreview = dataLoader => {
  const [isPreviewMode, setIsPreviewMode] = useState(false)
  const [previewData, setPreviewData] = useState({})

  useEffect(() => {
    const paths = usePathname()

    const fetchData = async () => {
      const data = await dataLoader()
      setPreviewData(data)
      setIsPreviewMode(true)
    }

    if (process.env.GATSBY_PREVIEW_ENABLED === 'true' && isPreview(paths)) {
      fetchData()
    }
  }, [])

  return { isPreviewMode, previewData }
}

export default usePreview
