import { useCallback, useEffect, useState, useRef } from 'react'
import { theme } from 'styles/themes'

const BREAKPOINTS = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
}

const useMatchMedia = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState()
  const tabletMediaRef = useRef()
  const mobileMediaRef = useRef()

  const checkMedia = useCallback(() => {
    if (!tabletMediaRef.current.matches && !mobileMediaRef.current.matches) {
      setCurrentBreakpoint(BREAKPOINTS.DESKTOP)
    } else if (
      tabletMediaRef.current.matches &&
      !mobileMediaRef.current.matches
    ) {
      setCurrentBreakpoint(BREAKPOINTS.TABLET)
    } else {
      setCurrentBreakpoint(BREAKPOINTS.MOBILE)
    }
  }, [])

  useEffect(() => {
    tabletMediaRef.current = window.matchMedia(
      `(max-width: ${theme.Breakpoints.tablet}) and (min-width: ${theme.Breakpoints.mobile})`,
    )
    mobileMediaRef.current = window.matchMedia(
      `(max-width: ${theme.Breakpoints.mobile})`,
    )
    checkMedia()

    tabletMediaRef.current.addListener(checkMedia)
    mobileMediaRef.current.addListener(checkMedia)

    return () => {
      tabletMediaRef.current.removeListener(checkMedia)
      mobileMediaRef.current.removeListener(checkMedia)
    }
  }, [])

  return {
    isDesktop: currentBreakpoint === BREAKPOINTS.DESKTOP,
    isTablet: currentBreakpoint === BREAKPOINTS.TABLET,
    isMobile: currentBreakpoint === BREAKPOINTS.MOBILE,
  }
}

export default useMatchMedia
