import React from 'react'

import BaseButton from './BaseButton'

const TextButton = ({ children, ...props }) => (
  <BaseButton variant="outlined" color="primary" {...props}>
    {children}
  </BaseButton>
)

export default TextButton
