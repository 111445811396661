import React from 'react'

import { Headline3 } from 'components/Texts'
import { Search as SearchIcon, Close } from 'components/Icons'

import Translation from 'utils/Translation'
import useTranslation from 'utils/Translation/useTranslation'
import { useMatchMedia } from 'hooks'

import {
  Input,
  CloseButton,
  SearchWrapper,
  SearchIconWrapper,
  SearchInputWrapper,
  SearchResultsWrapper,
} from './styledComponents'
import { ResultItem } from './ResultItem'
import useSearch from './useSearch'

const SearchComponent = ({ searchIndex }) => {
  const {
    query,
    results,
    inputRef,
    isSearchMenuOpen,
    setIsSearchMenuOpen,
    blurAndClear,
    searchIconRef,
    performSearch,
  } = useSearch(searchIndex)
  const { isMobile } = useMatchMedia()

  return (
    <SearchWrapper
      id="search-overlay"
      isSearchMenuOpen={isSearchMenuOpen}
      shouldHide={!isMobile && !isSearchMenuOpen}
    >
      {isSearchMenuOpen && (
        <>
          <CloseButton onClick={blurAndClear}>
            <Close />
          </CloseButton>

          <Headline3 extraBold mb="32px">
            <Translation id="search" />
          </Headline3>
        </>
      )}

      <SearchInputWrapper {...{ isSearchMenuOpen }}>
        <SearchIconWrapper
          ref={searchIconRef}
          onClick={() => setIsSearchMenuOpen(true)}
          shouldHide={!isMobile && !isSearchMenuOpen}
        >
          <SearchIcon />
        </SearchIconWrapper>

        <Input
          type="text"
          id="search"
          ref={inputRef}
          value={query}
          onChange={performSearch}
          placeholder={useTranslation('search')}
          {...{ isSearchMenuOpen }}
        />
      </SearchInputWrapper>

      {isSearchMenuOpen && (
        <SearchResultsWrapper>
          {results.map(result => (
            <ResultItem key={result.id} {...{ result, query }} />
          ))}
        </SearchResultsWrapper>
      )}
    </SearchWrapper>
  )
}

export default SearchComponent
