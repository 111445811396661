import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import { theme } from 'styles/themes'

import HeadlineBase from './HeadlineBase'

export const headline3Styles = css`
  font-size: 48px;
  line-height: 1.17;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    font-size: 36px;
    line-height: 1.11;
  }
`

const Headline3 = styled(HeadlineBase)`
  ${headline3Styles};
`

export default defaultProps({
  as: 'h3',
})(Headline3)
