import styled, { css } from 'styled-components'
import { defaultProps } from 'recompose'

import { theme } from 'styles/themes'

import HeadlineBase from './HeadlineBase'

export const headline2Styles = css`
  font-size: 60px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    font-size: 48px;
    line-height: 1.88;
  }
`

const Headline2 = styled(HeadlineBase)`
  ${headline2Styles};
`

export default defaultProps({
  as: 'h2',
})(Headline2)
