import React from 'react'

import { NoStylesExternalLink } from 'components/Links'
import { PrimaryButton, TextButton } from 'components/Buttons'

import { PLATFORM_ACTION_LINKS } from '../consts'

/* eslint-disable react/no-array-index-key */

const AccountButtons = () =>
  PLATFORM_ACTION_LINKS.map(({ buttonLabel, options }, index) => {
    const ButtonComponent = index === 0 ? TextButton : PrimaryButton

    return (
      <NoStylesExternalLink
        href={options[0].href}
        {...(index === 0 && { style: { marginRight: '8px' } })}
      >
        <ButtonComponent noPadding>{buttonLabel}</ButtonComponent>
      </NoStylesExternalLink>
    )
  })

export default AccountButtons
