import React from 'react'

const Xing = ({ width = '48px', height = '48px', ...rest }) => (
  <svg viewBox="0 0 48 48" width={width} height={height} {...rest}>
    <path
      fill="#0B6566"
      fillRule="evenodd"
      d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24zm6.513-37.823L21.01 26.786l6.122 10.688h6.411l-6.12-10.688 9.487-16.61h-6.397zm-12.21 4.952h-6.03l3.551 6.18-4.759 7.757h6.029l4.743-7.756-3.534-6.18z"
      clipRule="evenodd"
    />
  </svg>
)

export default Xing
