import styled, { css } from 'styled-components'
import { defaultProps, withProps, compose } from 'recompose'

import { theme } from 'styles/themes'

import BodyBase from './BodyBase'

export const buttonTextStyles = css`
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.15px;
`

const ButtonText = styled(BodyBase)`
  ${buttonTextStyles};
  cursor: pointer;
  ${({ secondary }) => secondary && `color: ${theme.Colors.secondary}`};
`

export default compose(
  withProps({
    medium: true,
  }),
  defaultProps({
    as: 'span',
  }),
)(ButtonText)
