import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import { theme } from 'styles/themes'

export const ButtonContent = styled.div`
  padding: 0 16px;
  ${({ noPadding }) => noPadding && `padding: 0`};

  && {
    -webkit-appearance: initial;
  }

  @media (max-width: ${theme.Breakpoints.mobile}) {
    padding: 0;
  }
`

export const BaseButtonStyles = styled(Button)`
  ${({ width }) => width && `width: ${width}`};
  position: relative;

  && {
    border-radius: 4px;
    text-transform: none;
  }
`
