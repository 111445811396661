import { useState, useEffect, useRef } from 'react'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Index } from 'elasticlunr'
import _ from 'lodash'

import { useLayout } from 'components/Layout/context'

import { handleEscape, handleEnterPress } from './utils'

const useSearch = searchIndex => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [index, setIndex] = useState()
  const { isSearchMenuOpen, setIsSearchMenuOpen } = useLayout()

  const inputRef = useRef(null)
  const searchIconRef = useRef(null)

  useEffect(() => {
    if (isSearchMenuOpen) {
      inputRef.current.focus()
    }
  }, [isSearchMenuOpen])

  useEffect(() => {
    handleEscape(setIsSearchMenuOpen)
  }, [])

  useEffect(() => {
    handleEnterPress(searchIconRef.current)
  }, [searchIconRef])

  useEffect(() => {
    // useRef + body scroll lock doesn't work with safari on iOS
    const overlayElement = document.querySelector('#search-overlay')

    if (overlayElement) {
      if (isSearchMenuOpen) {
        disableBodyScroll(overlayElement)
      } else {
        clearAllBodyScrollLocks(overlayElement)
      }
    }
  }, [isSearchMenuOpen])

  const blurAndClear = () => {
    setIsSearchMenuOpen(false)
    setResults([])
  }

  const getOrCreateIndex = () => {
    if (index) return index

    return Index.load(searchIndex)
  }

  const performSearch = event => {
    setIsSearchMenuOpen(true)

    const searchQuery = _.get(event, 'target.value')
    setQuery(searchQuery)

    const indexValues = getOrCreateIndex()
    setIndex(indexValues)

    const searchResults = indexValues
      .search(searchQuery, { expand: true })
      .map(({ ref }) => indexValues.documentStore.getDoc(ref))
    setResults(searchResults)
  }

  return {
    query,
    results,
    inputRef,
    isSearchMenuOpen,
    setIsSearchMenuOpen,
    blurAndClear,
    performSearch,
  }
}

export default useSearch
