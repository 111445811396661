import React from 'react'
import _ from 'lodash'
import { navigate } from 'gatsby'
import { Flex } from '@rebass/grid'

import { Body2, Headline5 } from 'components/Texts'

import { SearchResult, ImageWrapper } from './styledComponents'
import { highlightText } from './utils'

export const ResultItem = ({
  query,
  result: {
    description: { description } = {},
    heroImage: { file: image } = {},
    tags: tagsValues,
    title,
    slug,
    id,
  },
}) => {
  const tags = _.compact(_.castArray(tagsValues))
  const { url, filename } = image || {}

  return (
    <SearchResult onClick={() => navigate(`/blog/${slug}`)}>
      <ImageWrapper src={url} alt={filename} />

      <Flex flexDirection="column">
        {tags.length > 0 && (
          <Body2 gray>{highlightText(id, tags.join(` & `), query)}</Body2>
        )}

        <Headline5 bold>{highlightText(id, title, query)}</Headline5>

        <Body2 gray pt="12px">
          {description}
        </Body2>
      </Flex>
    </SearchResult>
  )
}
