import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import { theme } from 'styles/themes'

export const Page = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  color: ${theme.Colors.highBlack};
`

export const Main = styled.main`
  width: 100%;
  position: relative;
  flex-grow: 1;
`

export const Inner = styled.div`
  max-width: ${theme.Sizes.inner};
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
`

export const HeaderWrapper = styled(Flex)`
  background-color: ${({ bg }) => bg || theme.NewColors.light};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: ${theme.Indexes.stickyItems + 1};
  left: 0;
  right: 0;
  height: ${theme.Sizes.headerHeight};
  @media (max-width: ${theme.Breakpoints.tablet}) {
    height: ${theme.Sizes.mobileHeaderHeight};
  }
`

export const InternalWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
`

export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 40px 0;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
`

export const ColumnGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 350px;
  grid-column-gap: 30px;
  @media (max-width: ${theme.Breakpoints.tablet}) {
    grid-template-columns: 1fr;
    max-width: 730px;
  }
`

export const ColorWrapper = styled.div`
  background-color: ${({ color }) => color || '#fafafa'};
`

export const DesktopHideWrapper = styled(Flex)`
  display: none;
  @media (max-width: ${theme.Breakpoints.tablet}) {
    ${({ isTabletNotIncluded }) => isTabletNotIncluded && 'display: flex'};
  }
  @media (max-width: ${theme.Breakpoints.mobile}) {
    display: flex;
  }
`

export const MobileHideWrapper = styled(Flex)`
  @media (max-width: ${theme.Breakpoints.tablet}) {
    ${({ isTabletIncluded }) => isTabletIncluded && 'display: none'};
  }
  @media (max-width: ${theme.Breakpoints.mobile}) {
    display: none;
  }
`

export const MobileIconWrapper = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    font-size: 20px;
  }

  ${({ isInteractive }) => isInteractive && `cursor: pointer;`}
`

export const LogoWrapper = styled(Flex)`
  @media (max-width: ${theme.Breakpoints.mobile}) {
    max-width: 117px;
  }
`

export const GenericHeaderDropdownWrapper = styled(Box)`
  :not(:last-of-type) {
    margin-right: 24px;
  }
`
