import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import SearchComponent from './SearchComponent'

const Search = () => (
  <StaticQuery
    query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => (
      <SearchComponent searchIndex={data.siteSearchIndex.index} />
    )}
  />
)

export default Search
