import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import { body1Styles, bodyBaseStyles } from 'components/Texts'

import { theme } from 'styles/themes'

export const SearchWrapper = styled(Flex)`
  flex: 0;

  ${({ isSearchMenuOpen }) =>
    isSearchMenuOpen
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(255, 255, 255, 0.97);

          flex-direction: column;
          align-items: center;
          padding: 32px;

          overflow-y: auto;
        `
      : css`
          @media (min-width: ${theme.Breakpoints.mobile}) {
            position: absolute;
          }
        `}
`

export const SearchIconWrapper = styled.button`
  flex: 0;
  border: none;
  background: transparent;

  ${({ isSearchMenuOpen }) =>
    !isSearchMenuOpen &&
    `
      cursor: pointer;
    `}
  ${({ shouldHide }) =>
    shouldHide &&
    `
      display: none;
    `}
`

export const SearchInputWrapper = styled(Flex)`
  border-radius: 4px;
  width: 100%;
  max-width: 1108px;
  align-items: center;
  border: solid 1px ${theme.Colors.primary400};
  outline: none;

  ${({ isSearchMenuOpen }) =>
    !isSearchMenuOpen &&
    `
      border: none;
      justify-content: flex-end;
    `}
`

export const Input = styled.input`
  ${bodyBaseStyles};
  ${body1Styles};

  margin-left: 16px;
  border: none;
  width: 100%;
  background-color: transparent;

  :focus {
    outline: none;
  }

  ${({ isSearchMenuOpen }) =>
    !isSearchMenuOpen &&
    `
      display: none;
    `}
`

export const SearchResultsWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 32px;
  padding-bottom: 100px;
  flex: 0;
  max-width: 1108px;
  width: 100%;
`

// Flex doesn't work for search result item on safari
export const SearchResult = styled.div`
  flex: 1;
  padding: 8px;
  z-index: 100;
  cursor: pointer;

  margin-bottom: 24px;

  @media (max-width: ${theme.Breakpoints.mobile}) {
    flex-direction: column;
    height: initial;
    width: 100%;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 101;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`

export const ImageWrapper = styled.img`
  width: 248px;
  height: auto;
  margin: 0 24px 0 0;
  border-radius: 3px;

  @media (max-width: ${theme.Breakpoints.mobile}) {
    margin: 0 0 24px 0;
  }
`
