import styled from 'styled-components'

import quoteImage from 'components/Images/quote.svg'
import { theme } from 'styles/themes'
import {
  body1Styles,
  bodyBaseStyles,
  headline2Styles,
  headline3Styles,
  headline4Styles,
  headlineBaseStyles,
} from 'components/Texts'

export const Sidebar = styled.aside`
  position: sticky;
  top: 90px;
`

export const ArticleContainer = styled.div`
  margin-bottom: 72px;
  max-width: 635px;
`

export const ArticleFooter = styled.p`
  font-size: 12px;
  color: ${theme.Colors.primary600};
`

export const HTMLWrapper = styled.div`
  ${bodyBaseStyles};
  ${body1Styles};

  h1,
  h2,
  h3 {
    ${headlineBaseStyles};
    margin-top: 72px;
    margin-bottom: 32px;
    word-break: break-word;
  }

  h1 {
    line-height: normal;
    font-weight: bold;
    ${headline2Styles};
  }

  h2 {
    line-height: normal;
    font-weight: bold;
    ${headline3Styles};
  }

  h3 {
    line-height: normal;
    font-weight: bold;
    ${headline4Styles};
  }

  blockquote {
    font-size: 21px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.52;
    letter-spacing: 0.2px;
    padding-left: 20%;
    margin: 40px 0 0;
    background-image: url(${quoteImage});
    background-size: 13%;
    background-repeat: no-repeat;
    strong {
      display: block;
      margin-top: 8px;
      font-style: normal;
      width: 100%;
      font-size: 18px;
    }
    @media (max-width: ${theme.Breakpoints.mobile}) {
      background-size: 12%;
      padding-left: 15%;
      font-size: 18px;
    }
  }

  p {
    margin-bottom: 32px;

    ${({ themeColor }) =>
      themeColor && `color: ${theme.NewColors[themeColor]}`};
  }

  a {
    color: ${theme.Colors.secondary};
  }

  img {
    max-width: 100%;
  }
`
