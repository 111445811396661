import React from 'react'

import BaseButton from './BaseButton'

const PrimaryButton = ({ children, ...props }) => (
  <BaseButton color="primary" variant="contained" disableElevation {...props}>
    {children}
  </BaseButton>
)

export default PrimaryButton
