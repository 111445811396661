import React from 'react'
import { Box } from '@rebass/grid'
import _ from 'lodash'

import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { Body1, Link } from 'components/Texts'

import { Inner, ColorWrapper, FooterGrid } from '../styledComponents'

const FooterComponent = data => {
  const columns = _.chunk(
    Object.values(_.get(data, 'allContentfulFooter.nodes[0]')),
    2,
  )

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Body1 block>{children}</Body1>,
      [INLINES.HYPERLINK]: (node, children) => (
        <Link href={_.get(node, 'data.uri')}>{children}</Link>
      ),
    },
  }

  return (
    <ColorWrapper as="footer" color="#f8f9fa">
      <Inner>
        <FooterGrid>
          {columns.map((column, index) => (
            <Box key={index}>
              <Body1 fullWidth bold mb="15px">
                {_.first(column)}
              </Body1>

              {documentToReactComponents(
                _.get(_.last(column), 'json'),
                options,
              )}
            </Box>
          ))}
        </FooterGrid>
      </Inner>
    </ColorWrapper>
  )
}

export default FooterComponent
